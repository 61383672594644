import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import {
    FormOutlined,
    LockOutlined,
    PlusOutlined,
    ShopOutlined,
    UsergroupAddOutlined,
    AppstoreAddOutlined,
    ArrowDownOutlined,
    ArrowRightOutlined,
    EditOutlined,
    DownloadOutlined,
    LineChartOutlined,
    RedEnvelopeOutlined,
    PartitionOutlined,
    PlaySquareOutlined,
} from '@ant-design/icons';
import { Tooltip, Collapse, Typography } from 'antd';
import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { GlobalContext } from '../../context/GlobalContextProvider';
const strings = new LocalizedStrings({
    en: {
        booths: 'BOOTHS',
        addBooth: 'Add Booth',
        eventModules: 'EVENT MODULES',
        addEventModule: 'Add Event Module',
        visitorList: 'Visitor List',
        boothCategories: 'Booth Categories',
        profileFields: 'Profile Fields',
        uncategorized: 'Un-categorized',
        downloadBasket: 'Download Basket',
        modules: 'BOOTH MODULES',
        analytics: 'Analytics',
        emailTemplates: 'Email Templates',
        boothTree: 'Booth Tree',
        liveStreams: 'Call for Papers',
    },
    de: {
        booths: 'Stände',
        addBooth: 'Stand hinzufügen',
        eventModules: 'Eventmodule',
        addEventModule: 'Eventmodul hinzufügen',
        visitorList: 'Besucherliste',
        boothCategories: 'Standkategorien',
        profileFields: 'Profilfelder',
        uncategorized: 'Nichtkategorisierte Aussteller',
        downloadBasket: 'Warenkorb',
        modules: 'Standmodule',
        analytics: 'Analysen',
        emailTemplates: 'E-Mail-Vorlagen',
        boothTree: 'Standbaum',
        liveStreams: 'Call for Papers',
    },
});

const Menu = styled.div``;
const MenuCategoryTitle = styled.div`
    color: #8cabf2;
    font-weight: bold;
`;
const MenuItemList = styled.div`
    color: white;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;
`;
const MenuItem = styled.div<{ disabled?: boolean }>`
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:hover {
        background-color: rgb(18, 61, 161);
        border-radius: 6px;
        cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`;

export const BoothMenu = ({
    setActiveMenuItem,
    showAddBoothDrawer,
    showAddEventModuleDrawer,
}: {
    setActiveMenuItem: Dispatch<
        SetStateAction<{
            type?: 'booth' | 'eventModule';
            booth?: Booths.Booth;
            module?: Modules.Module;
            custom?:
                | 'visitorList'
                | 'boothCategories'
                | 'customUserProfileFields'
                | 'downloadBasketConfig'
                | 'boothAnalytics'
                | 'eventAnalytics'
                | 'emailTemplates'
                | 'boothTree'
                | 'liveStreams';
        }>
    >;
    showAddBoothDrawer: () => void;
    showAddEventModuleDrawer: () => void;
}) => {
    const context = useContext(GlobalContext);
    const [clientId] = useQueryParam('clientId', StringParam);
    const [eventId] = useQueryParam('eventId', StringParam);
    const selectBooth = ({ booth }: { booth: Booths.Booth }) => {
        navigate(`/event?clientId=${clientId}&eventId=${eventId}&boothId=${booth._id}`);
        setActiveMenuItem({ type: 'booth', booth: booth });
    };
    const loggedInUser = store.get('user') as Users.User;
    const boothCategoryIds = context.activeEvent?.categories?.map(category => category._id) || [];

    const checkUserHasAccessToBooth = ({ booth }: { booth: Booths.Booth }) => {
        const sponsors = booth.sponsors;
        const staffs = context?.activeEvent?.staffs;
        const userIsSuperOperator =
            loggedInUser.roles?.includes('super-admin') || loggedInUser.roles?.includes('admin');
        if (userIsSuperOperator) return true;
        else if (loggedInUser.roles?.includes('staff')) {
            const staffIds = staffs?.map(staff => (typeof staff === 'string' ? staff : staff._id));
            const userId = loggedInUser._id;
            if (staffIds?.includes(userId)) return true;
            else return false;
        } else if (loggedInUser.roles?.includes('sponsor')) {
            const sponsorIds = sponsors?.map(sponsor => (typeof sponsor === 'string' ? sponsor : sponsor._id));
            const userId = loggedInUser._id;
            if (sponsorIds?.includes(userId)) return true;
            else return false;
        }
    };

    const categories = useMemo(() => {
        const categoryIds = context.activeEvent?.categories?.map(c => c._id) || [];
        const nonCategoryBooths = context.booths
            .filter(booth => !categoryIds.includes(booth.category!) || !booth.category)
            .filter(booth => checkUserHasAccessToBooth({ booth }));
        return (
            context.activeEvent?.categories
                ?.map(
                    category =>
                        ({ name: category.name, id: category._id, key: category._id, booths: [] } as {
                            name?: string;
                            id?: string;
                            key: string;
                            booths?: Booths.Booth[];
                        }),
                )
                .sort((cat1, cat2) => {
                    if (!cat1.name || !cat2.name) return 0;
                    else if (cat1?.name < cat2?.name) return -1;
                    else if (cat1?.name > cat2.name) return 1;
                    else return 0;
                })
                .map(category => {
                    return {
                        ...category,
                        booths: context.booths
                            .filter(booth => booth.category === category.id)
                            .filter(booth => checkUserHasAccessToBooth({ booth }))
                            .sort((booth1, booth2) => {
                                if (!booth1.name || !booth2.name) return 0;
                                else if (booth1?.name < booth2?.name) return -1;
                                else if (booth1?.name > booth2.name) return 1;
                                else return 0;
                            }),
                    };
                })
                .concat({
                    name: strings.uncategorized,
                    id: 'uncategorized-category',
                    key: 'uncategorized-category',
                    booths: nonCategoryBooths,
                })
                .filter(category => category.booths && category.booths.length > 0) || []
        );
    }, [context.activeEvent, context.booths]);

    return (
        <Menu>
            <MenuCategoryTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{strings.booths}</span>{' '}
                    {loggedInUser?.roles?.includes('super-admin') ? (
                        <Tooltip title={strings.addBooth}>
                            <PlusOutlined onClick={showAddBoothDrawer} />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </div>
            </MenuCategoryTitle>

            <MenuItemList>
                <Collapse
                    bordered={false}
                    ghost
                    expandIcon={(props: { isActive?: boolean }) =>
                        props.isActive ? (
                            <ArrowDownOutlined style={{ color: 'white' }} />
                        ) : (
                            <ArrowRightOutlined style={{ color: 'white' }} />
                        )
                    }
                >
                    {categories.map(category => {
                        return (
                            <Collapse.Panel
                                header={
                                    <Typography.Text style={{ color: 'white', marginBottom: 0 }}>
                                        {category.name}
                                    </Typography.Text>
                                }
                                key={category.key}
                            >
                                {category.booths.map(booth => {
                                    return (
                                        <MenuItem
                                            key={`booth-${booth._id}`}
                                            onClick={() => selectBooth({ booth })}
                                            style={{ marginBottom: '0.5rem' }}
                                            disabled={false}
                                        >
                                            <ShopOutlined style={{ marginRight: '0.5rem', color: 'white' }} />
                                            <Typography.Text style={{ color: 'white' }}>{booth.name}</Typography.Text>
                                        </MenuItem>
                                    );
                                })}
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            </MenuItemList>

            {/* <MenuItemList>
                <Collapse
                    bordered={false}
                    ghost
                    expandIcon={(props: { isActive?: boolean }) =>
                        props.isActive ? (
                            <ArrowDownOutlined style={{ color: 'white' }} />
                        ) : (
                            <ArrowRightOutlined style={{ color: 'white' }} />
                        )
                    }
                >
                    {context.activeEvent?.categories
                        ?.sort((cat1, cat2) => {
                            if (!cat1.name || !cat2.name) return 0;
                            else if (cat1?.name < cat2?.name) return -1;
                            else if (cat1?.name > cat2.name) return 1;
                            else return 0;
                        })
                        .map(category => {
                            return (
                                <Collapse.Panel
                                    header={
                                        <Typography.Text style={{ color: 'white', marginBottom: 0 }}>
                                            {category.name}
                                        </Typography.Text>
                                    }
                                    key={category._id}
                                >
                                    {context.booths
                                        .sort((booth1, booth2) => {
                                            if (!booth1.name || !booth2.name) return 0;
                                            else if (booth1?.name < booth2?.name) return -1;
                                            else if (booth1?.name > booth2.name) return 1;
                                            else return 0;
                                        })
                                        .map(booth => {
                                            const MenuItemComponentDisabled = () => (
                                                <Tooltip
                                                    title="You do not have access"
                                                    placement="right"
                                                    color="volcano"
                                                >
                                                    <MenuItem
                                                        key={`booth-${booth._id}`}
                                                        style={{ marginBottom: '0.5rem' }}
                                                        disabled={true}
                                                    >
                                                        <ShopOutlined
                                                            style={{ marginRight: '0.5rem', color: 'white' }}
                                                        />
                                                        <Typography.Text style={{ color: 'white' }}>
                                                            {booth.name}
                                                        </Typography.Text>
                                                    </MenuItem>
                                                </Tooltip>
                                            );

                                            const MenuItemComponentEnabled = () => (
                                                <MenuItem
                                                    key={`booth-${booth._id}`}
                                                    onClick={() => selectBooth({ booth })}
                                                    style={{ marginBottom: '0.5rem' }}
                                                    disabled={false}
                                                >
                                                    <ShopOutlined style={{ marginRight: '0.5rem', color: 'white' }} />
                                                    <Typography.Text style={{ color: 'white' }}>
                                                        {booth.name}
                                                    </Typography.Text>
                                                </MenuItem>
                                            );

                                            if (booth.category === category._id) {
                                                const userHasAccessToBooth = checkUserHasAccessToBooth({
                                                    booth: booth,
                                                });
                                                return userHasAccessToBooth ? (
                                                    <MenuItemComponentEnabled key={`enabled-booth-${booth._id}`} />
                                                ) : (
                                                    <MenuItemComponentDisabled key={`disabled-booth-${booth._id}`} />
                                                );
                                            }
                                        })}
                                </Collapse.Panel>
                            );
                        })}
                    <Collapse.Panel
                        header={
                            <Typography.Text style={{ color: 'white', marginBottom: 0 }}>
                                {strings.uncategorized}
                            </Typography.Text>
                        }
                        key={'uncategorized-category'}
                    >
                        {context.booths
                            .sort((booth1, booth2) => {
                                if (!booth1.name || !booth2.name) return 0;
                                else if (booth1?.name < booth2?.name) return -1;
                                else if (booth1?.name > booth2.name) return 1;
                                else return 0;
                            })
                            // .map(
                            //     booth =>
                            //         !booth.category && (
                            //             <MenuItem
                            //                 key={`booth-${booth._id}`}
                            //                 onClick={() => selectBooth({ booth })}
                            //                 style={{ marginBottom: '0.5rem' }}
                            //             >
                            //                 <ShopOutlined style={{ marginRight: '0.5rem', color: 'white' }} />
                            //                 <Typography.Text style={{ color: 'white' }}>{booth.name}</Typography.Text>
                            //             </MenuItem>
                            //         ),
                            // )}
                            .map(booth => {
                                const MenuItemComponentDisabled = () => (
                                    <Tooltip
                                        title="You do not have access"
                                        placement="right"
                                        color="volcano"
                                        key={`tool-tip-booth-${booth._id}`}
                                    >
                                        <MenuItem
                                            key={`booth-${booth._id}`}
                                            style={{ marginBottom: '0.5rem' }}
                                            disabled={true}
                                        >
                                            <ShopOutlined style={{ marginRight: '0.5rem', color: 'white' }} />
                                            <Typography.Text style={{ color: 'white' }}>{booth.name}</Typography.Text>
                                        </MenuItem>
                                    </Tooltip>
                                );

                                const MenuItemComponentEnabled = () => (
                                    <MenuItem
                                        key={`booth-${booth._id}`}
                                        onClick={() => selectBooth({ booth })}
                                        style={{ marginBottom: '0.5rem' }}
                                        disabled={false}
                                    >
                                        <ShopOutlined style={{ marginRight: '0.5rem', color: 'white' }} />
                                        <Typography.Text style={{ color: 'white' }}>{booth.name}</Typography.Text>
                                    </MenuItem>
                                );

                                if (!booth.category || !boothCategoryIds.includes(booth.category)) {
                                    const userHasAccessToBooth = checkUserHasAccessToBooth({
                                        booth: booth,
                                    });
                                    return userHasAccessToBooth ? (
                                        <MenuItemComponentEnabled key={`enabled-booth-${booth._id}`} />
                                    ) : (
                                        <MenuItemComponentDisabled key={`disabled-booth-${booth._id}`} />
                                    );
                                }
                            })}
                    </Collapse.Panel>
                </Collapse>
            </MenuItemList> */}

            <MenuCategoryTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{strings.modules}</span>{' '}
                </div>
            </MenuCategoryTitle>
            {loggedInUser?.roles?.includes('sponsor') && (
                <>
                    <MenuItemList>
                        <MenuItem
                            key={`module-liveStreams`}
                            onClick={() => setActiveMenuItem({ custom: 'liveStreams' })}
                        >
                            <PlaySquareOutlined style={{ marginRight: '0.5rem' }} /> {strings.liveStreams}
                        </MenuItem>
                    </MenuItemList>
                </>
            )}
            {(loggedInUser?.roles?.includes('super-admin') ||
                loggedInUser?.roles?.includes('admin') ||
                loggedInUser?.roles?.includes('staff')) && (
                <>
                    <MenuItemList>
                        <MenuItem
                            key={`module-visitorList`}
                            onClick={() => setActiveMenuItem({ custom: 'visitorList' })}
                        >
                            <UsergroupAddOutlined style={{ marginRight: '0.5rem' }} /> {strings.visitorList}
                        </MenuItem>
                    </MenuItemList>
                </>
            )}

            {(loggedInUser?.roles?.includes('super-admin') ||
                loggedInUser?.roles?.includes('admin') ||
                loggedInUser?.roles?.includes('staff')) && (
                <>
                    <MenuCategoryTitle>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{strings.eventModules}</span>{' '}
                            {loggedInUser?.roles?.includes('super-admin') && (
                                <Tooltip title={strings.addEventModule}>
                                    <PlusOutlined onClick={showAddEventModuleDrawer} />
                                </Tooltip>
                            )}
                        </div>
                    </MenuCategoryTitle>
                    <MenuItemList style={{ paddingBottom: '3rem' }}>
                        {context.eventModules.map(eventModule => (
                            <MenuItem
                                key={`module-${eventModule._id}`}
                                onClick={() => setActiveMenuItem({ type: 'eventModule', module: eventModule })}
                            >
                                {eventModule.type === 'authentication' ? (
                                    <LockOutlined style={{ marginRight: '0.5rem' }} />
                                ) : eventModule.type === 'forms' ||
                                  eventModule.type === 'forms-ext' ||
                                  eventModule.type === 'forms-siemens' ? (
                                    <FormOutlined style={{ marginRight: '0.5rem' }} />
                                ) : (
                                    <ShopOutlined style={{ marginRight: '0.5rem' }} />
                                )}

                                {eventModule.type === 'forms-ext'
                                    ? 'External Form'
                                    : eventModule.type === 'forms-siemens'
                                    ? 'Siemens Form'
                                    : eventModule.type}
                            </MenuItem>
                        ))}
                        <MenuItem
                            key={`module-boothCategories`}
                            onClick={() => setActiveMenuItem({ custom: 'boothCategories' })}
                        >
                            <AppstoreAddOutlined style={{ marginRight: '0.5rem' }} />
                            {strings.boothCategories}
                        </MenuItem>
                        <MenuItem
                            key={`module-customUserProfileFields`}
                            onClick={() => setActiveMenuItem({ custom: 'customUserProfileFields' })}
                        >
                            <EditOutlined style={{ marginRight: '0.5rem' }} />
                            {strings.profileFields}
                        </MenuItem>
                        <MenuItem
                            key={`module-downloadBasketConfig`}
                            onClick={() => setActiveMenuItem({ custom: 'downloadBasketConfig' })}
                        >
                            <DownloadOutlined style={{ marginRight: '0.5rem' }} />
                            {strings.downloadBasket}
                        </MenuItem>
                        <MenuItem
                            key={`module-event-analytics`}
                            onClick={() => setActiveMenuItem({ custom: 'eventAnalytics' })}
                        >
                            <LineChartOutlined style={{ marginRight: '0.5rem' }} />
                            {strings.analytics}
                        </MenuItem>
                        <MenuItem
                            key={`module-email-templates`}
                            onClick={() => setActiveMenuItem({ custom: 'emailTemplates' })}
                        >
                            <RedEnvelopeOutlined style={{ marginRight: '0.5rem' }} />
                            {strings.emailTemplates}
                        </MenuItem>
                        {!loggedInUser?.roles?.includes('sponsor') && (
                            <MenuItem key={`booth-tree`} onClick={() => setActiveMenuItem({ custom: 'boothTree' })}>
                                <PartitionOutlined style={{ marginRight: '0.5rem' }} />
                                {strings.boothTree}
                            </MenuItem>
                        )}
                    </MenuItemList>
                </>
            )}
        </Menu>
    );
};

export default BoothMenu;
