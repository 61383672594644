import React from 'react';

import styled from 'styled-components';

const SidebarWrapper = styled.div<{ level: number }>`
    width: 20vw;
    background-color: ${props => (props.level === 0 ? '#1853db' : 'rgb(18, 61, 161)')};
    height: 100%;
    padding: 1.5rem;
    position: fixed;
    margin-top: 4rem;
    margin-left: ${props => props.level * 20 + 'vw'};
    top: 0;
    left: 0;
    overflow-y: scroll;
`;

export const Sidebar = ({ children, level = 0 }: { children: any; level?: number }) => {
    return <SidebarWrapper level={level}>{children}</SidebarWrapper>;
};

export default Sidebar;
