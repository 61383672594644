import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Form, Input, Select, Space, Typography } from 'antd';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../../utility';

const { Paragraph } = Typography;
const { Option } = Select;
const strings = new LocalizedStrings({
    en: {
        boothCreated: 'Booth has been created',
        createBooth: 'Create New Booth',
        name: 'Name',
        enterName: 'Please enter client name',
        enterDescription: 'Please enter client description',
        staffs: 'Staffs',
        sponsors: 'Sponsors',
        category: 'Category',
        description: 'Description',
        availableModules: 'Available booth modules',
        fileDownloads: 'File Downloads',
        fileDownloadsDescription: 'Upload and change file download for booths in real-time.',
        webChat: 'Chat',
        chatDescription: 'Interact with booth visitors in real time via web chat.',
        videoCall: 'Video Call',
        callDescription: 'Interact with booth visitors in real time via video conferencing.',
    },
    de: {
        boothCreated: 'Stand wurde erzeugt',
        createBooth: 'Neuen Stand anlegen',
        name: 'Name',
        enterName: 'Bitte Kundennamen eingeben',
        enterDescription: 'Bitte Kundenbeschreibung eingeben',
        staffs: 'Mitarbeiter',
        sponsors: 'Sponsoren',
        category: 'Kategorie',
        description: 'Beschreibung',
        availableModules: 'Verfügbare Standmodule',
        fileDownloads: 'Dateidownloads',
        fileDownloadsDescription: 'Hochladen und Ändern von Download-Dateien in Echtzeit',
        webChat: 'Chat',
        chatDescription: 'Mit Besuchern in Echtzeit über Chat interagieren',
        videoCall: 'Videoanruf',
        callDescription: 'Mit Besuchern in Echtzeit über Videoanrufe interagieren',
    },
});

export const AddBoothDrawer = ({
    clientId,
    eventId,
    visible,
    setVisible,
}: {
    clientId: string;
    eventId: string;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [addForm] = Form.useForm();
    const [users, setUsers] = useState<Users.User[]>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        apiRequester.getOperators(clientId).then(setUsers).catch(handleError);
    }, [eventId]);

    const addBooth = async (booth: { name: string; description?: string; staffs: string[]; sponsors: string[] }) => {
        try {
            setLoading(true);
            booth = {
                ...booth,
                staffs: booth.staffs?.map(staff => staff.split(' - ')[0]) || [],
                sponsors: booth.sponsors?.map(sponsor => sponsor.split(' - ')[0]) || [],
            };
            await apiRequester.createBooth(clientId, eventId, booth);
            await context.refreshBooths(clientId, eventId);
            addForm.resetFields();
            handleSuccess(strings.boothCreated!);
            setVisible(false);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const closeDrawer = () => setVisible(false);

    return (
        <Drawer
            title={strings.createBooth}
            placement="right"
            closable={false}
            onClose={closeDrawer}
            visible={visible}
            width="620px"
            footer={
                <Space style={{ marginRight: '8px' }}>
                    <Button onClick={closeDrawer}>Cancel</Button>{' '}
                    <Button type="primary" loading={loading} onClick={addForm.submit}>
                        Create
                    </Button>
                </Space>
            }
            footerStyle={{ textAlign: 'right', marginRight: '8px' }}
            closeIcon={<CloseOutlined />}
        >
            <Form form={addForm} onFinish={addBooth}>
                <Form.Item
                    label={strings.name}
                    name="name"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: strings.enterName }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={strings.description}
                    label="Description"
                    labelCol={{ span: 6 }}
                    rules={[{ message: strings.enterDescription }]}
                >
                    <Input.TextArea />
                </Form.Item>
                {/* <Form.Item label={strings.staffs} name="staffs" labelCol={{ span: 6 }}>
                    <Select mode="multiple">
                        {users?.map(user => {
                            return user.roles?.includes('staff') ? (
                                <Option
                                    key={`add-staff-${user._id}`}
                                    value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                >
                                    {user.firstName} {user.lastName} - {user.emailId}
                                </Option>
                            ) : undefined;
                        })}
                    </Select>
                </Form.Item> */}
                <Form.Item label={strings.sponsors} name="sponsors" labelCol={{ span: 6 }}>
                    <Select mode="multiple">
                        {users?.map(user => {
                            return user.roles?.includes('sponsor') ? (
                                <Option
                                    key={`add-sponsor-${user._id}`}
                                    value={`${user._id} - ${user.firstName} ${user.lastName} - ${user.emailId}`}
                                >
                                    {user.firstName} {user.lastName} - {user.emailId}
                                </Option>
                            ) : undefined;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={strings.category} name="category" labelCol={{ span: 6 }}>
                    <Select allowClear={true}>
                        {context.activeEvent?.categories?.map(category => {
                            return (
                                <Option key={`add-category-${category._id}`} value={category._id}>
                                    {category.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
            <Divider />
            <Paragraph strong={true}>{strings.availableModules}</Paragraph>
            <Space direction="vertical">
                <Card>
                    <Paragraph strong={true}>{strings.fileDownloads}</Paragraph>
                    <Paragraph>{strings.fileDownloadsDescription}</Paragraph>
                </Card>
                <Card>
                    <Paragraph strong={true}>{strings.webChat}</Paragraph>
                    <Paragraph>{strings.chatDescription}</Paragraph>
                </Card>
                <Card>
                    <Paragraph strong={true}>{strings.videoCall}</Paragraph>
                    <Paragraph>{strings.videoCall}</Paragraph>
                </Card>
            </Space>
        </Drawer>
    );
};

export default AddBoothDrawer;
