import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Drawer, Button, Form, Select, List, Card, Space, Input, Typography, Popconfirm } from 'antd';
import LocalizedStrings from 'react-localization';

import { apiRequester, handleError, handleSuccess } from '../utility';

const strings = new LocalizedStrings({
    en: {
        addTemplate: 'Add template',
        target: 'Target',
        add: 'Add',
        addSuccess: 'Email template has been added successfully',
        targetRequired: 'Please provide target',
        templateDeleted: 'Email template has been deleted successfully',
        templatesUpdated: 'Email template has been updated successfully',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        addTemplate: 'Vorlage hinzufügen',
        target: 'Ziel',
        add: 'Hinzufügen',
        addSuccess: 'E-Mail-Vorlage wurde erfolgreich hinzugefügt',
        targetRequired: 'Bitte Ziel angeben',
        templateDeleted: 'E-Mail-Vorlage wurde erfolgreich gelöscht',
        templatesUpdated: 'E-Mail-Vorlage wurde erfolgreich aktualisiert',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const EmailTemplateCard = ({
    clientId,
    eventId,
    data,
    refreshEmailTemplates,
}: {
    clientId: string;
    eventId: string;
    data: EmailTemplates.EmailTemplate;
    refreshEmailTemplates: () => Promise<void>;
}) => {
    const [loading, setLoading] = useState(false);
    const [editForm] = Form.useForm();
    const { target, _id: templateId } = data;

    useEffect(() => {
        const { type, target, template } = data;
        editForm.setFieldsValue({ type, target, template });
    }, [data]);

    const deleteEmailTemplate = async () => {
        try {
            setLoading(true);
            await apiRequester.deleteEmailTemplate({ clientId, eventId, templateId: templateId! });
            handleSuccess(strings.templateDeleted!);
            await refreshEmailTemplates();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    const editEmailTemplate = async ({
        template,
        type,
        target,
    }: {
        template: string;
        type: EmailTemplates.EMAIL_TEMPLATE_TYPE;
        target: EmailTemplates.EMAIL_TEMPLATE_TARGETS;
    }) => {
        try {
            setLoading(true);
            await apiRequester.updateEmailTemplate({
                clientId,
                eventId,
                templateId: templateId!,
                template,
                type,
                target,
            });
            handleSuccess(strings.templatesUpdated!);
            await refreshEmailTemplates();
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card
            style={{ width: '100%' }}
            size="small"
            title={
                target === 'GUIDED_TOURS'
                    ? 'Guided Tours Notification'
                    : target === 'MEETING_ROOMS'
                    ? 'Meeting Rooms Notification'
                    : target === 'BOOTH_INVITATIONS'
                    ? 'Booth Invitations'
                    : target === 'VISITOR_TRIED_CONTACTING_NOTIFICATION'
                    ? 'Visitor Tried Reaching Booth'
                    : target === 'NEW_CALL_FOR_PAPER_REQUEST'
                    ? 'New Call For Paper Request'
                    : target === 'CALL_FOR_PAPER_REQUEST_ACCEPTED'
                    ? 'Call For Paper Request Accepted'
                    : target === 'CALL_FOR_PAPER_REQUEST_REJECTED'
                    ? 'Call For Paper Request Rejected'
                    : target === 'VISITOR_TRIED_CHATTING_NOTIFICATION'
                    ? 'Visitor tried chatting'
                    : ''
            }
            extra={
                <Space>
                    <Popconfirm
                        placement="topLeft"
                        title={strings.sure}
                        onConfirm={deleteEmailTemplate}
                        okText={strings.yes}
                        cancelText={strings.no}
                    >
                        <Button danger loading={loading}>
                            Delete
                        </Button>
                    </Popconfirm>
                    <Button type="primary" onClick={editForm.submit} loading={loading}>
                        Save
                    </Button>
                </Space>
            }
        >
            {target === 'GUIDED_TOURS' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{DATE_TIME}}`}</Typography.Text> - Date and time of meeting
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Booth name
                    </pre>
                </>
            ) : target === 'MEETING_ROOMS' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{DATE_TIME}}`}</Typography.Text> - Date and time of meeting
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Booth name
                    </pre>
                </>
            ) : target === 'BOOTH_INVITATIONS' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_LINK}}`}</Typography.Text> - Booth Link
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Booth name
                    </pre>
                </>
            ) : target === 'VISITOR_TRIED_CONTACTING_NOTIFICATION' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_FIRST_NAME}}`}</Typography.Text> - Visitor first name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_LAST_NAME}}`}</Typography.Text> - Visitor last name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_ID}}`}</Typography.Text> - Visitor ID
                    </pre>
                </>
            ) : target === 'NEW_CALL_FOR_PAPER_REQUEST' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{SPONSOR_FIRST_NAME}}`}</Typography.Text> - Sponsor first name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{SPONSOR_LAST_NAME}}`}</Typography.Text> - Sponsor last name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{MODULE_NAME}}`}</Typography.Text> - Live Streaming Module Name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Live Streaming Booth Name
                    </pre>
                </>
            ) : target === 'CALL_FOR_PAPER_REQUEST_ACCEPTED' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{MODULE_NAME}}`}</Typography.Text> - Live Streaming Module Name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Live Streaming Booth Name
                    </pre>
                </>
            ) : target === 'CALL_FOR_PAPER_REQUEST_REJECTED' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{MODULE_NAME}}`}</Typography.Text> - Live Streaming Module Name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Live Streaming Booth Name
                    </pre>
                </>
            ) : target === 'VISITOR_TRIED_CHATTING_NOTIFICATION' ? (
                <>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_FIRST_NAME}}`}</Typography.Text> - Visitor first name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_LAST_NAME}}`}</Typography.Text> - Visitor last name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{VISITOR_ID}}`}</Typography.Text> - Visitor ID
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{BOOTH_NAME}}`}</Typography.Text> - Booth Name
                    </pre>
                    <pre>
                        <Typography.Text code>{`{{MESSAGE}}`}</Typography.Text> - Message from Visitor
                    </pre>
                </>
            ) : (
                <></>
            )}
            <Form form={editForm} onFinish={editEmailTemplate}>
                <Form.Item name="type" hidden>
                    <Input.TextArea rows={10} />
                </Form.Item>
                <Form.Item name="target" hidden>
                    <Input.TextArea rows={10} />
                </Form.Item>
                <Form.Item name="template">
                    <Input.TextArea rows={10} />
                </Form.Item>
            </Form>
        </Card>
    );
};

export const EmailTemplates = ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplates.EmailTemplate[]>([]);
    const [showAddSlider, setShowAddSlider] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addForm] = Form.useForm();

    const refreshEmailTemplates = async () => {
        try {
            setLoading(true);
            const templates = await apiRequester.getEmailTemplates({ clientId, eventId });
            setEmailTemplates(templates);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshEmailTemplates();
    }, []);

    const addTemplate = async ({ target }: { target: EmailTemplates.EMAIL_TEMPLATE_TARGETS }) => {
        try {
            setLoading(true);
            await apiRequester.createEmailTemplate({ clientId, eventId, target: target, type: 'CLIENT', template: '' });
            handleSuccess(strings.addSuccess!);
            await refreshEmailTemplates();
            setShowAddSlider(false);
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Button
                    style={{ float: 'right' }}
                    type="primary"
                    onClick={() => setShowAddSlider(true)}
                    loading={loading}
                    icon={<PlusOutlined />}
                >
                    {strings.addTemplate}
                </Button>
                <List
                    dataSource={emailTemplates}
                    loading={loading}
                    renderItem={item => (
                        <List.Item>
                            <EmailTemplateCard
                                refreshEmailTemplates={refreshEmailTemplates}
                                clientId={clientId}
                                eventId={eventId}
                                data={item}
                            />
                        </List.Item>
                    )}
                />
            </Space>
            <Drawer
                visible={showAddSlider}
                onClose={() => setShowAddSlider(false)}
                width="620px"
                title={strings.addTemplate}
                footer={
                    <Button
                        type="primary"
                        style={{ float: 'right' }}
                        onClick={addForm.submit}
                        loading={loading}
                        icon={<PlusOutlined />}
                    >
                        {strings.add}
                    </Button>
                }
            >
                <Form onFinish={addTemplate} form={addForm}>
                    <Form.Item
                        name="target"
                        label={strings.target}
                        rules={[
                            {
                                required: true,
                                message: strings.targetRequired,
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value="GUIDED_TOURS">Guided Tours</Select.Option>
                            <Select.Option value="MEETING_ROOMS">Meeting Rooms</Select.Option>
                            <Select.Option value="BOOTH_INVITATIONS">Booth Invitations</Select.Option>
                            <Select.Option value="VISITOR_TRIED_CONTACTING_NOTIFICATION">
                                Visitor Tried Reaching Booth
                            </Select.Option>
                            <Select.Option value="NEW_CALL_FOR_PAPER_REQUEST">New call for paper request</Select.Option>
                            <Select.Option value="CALL_FOR_PAPER_REQUEST_ACCEPTED">
                                Call for paper request accepted
                            </Select.Option>
                            <Select.Option value="CALL_FOR_PAPER_REQUEST_REJECTED">
                                Call for paper request rejected
                            </Select.Option>
                            <Select.Option value="VISITOR_TRIED_CHATTING_NOTIFICATION">
                                Visitor tried Chatting
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default EmailTemplates;
