import React, { useContext, useEffect, useState } from 'react';

import { LeftCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Layout, PageHeader, Popconfirm, Row, Switch, Tag, Typography } from 'antd';
import { navigate } from 'gatsby';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import LocalizedStrings from 'react-localization';
import store from 'store';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import {
    AuthModule,
    BoothModules,
    BoothCategories,
    FormModule,
    ExtraUserProfileFields,
    VideoCallModal,
    EventAnalytics,
    BoothAnalytics,
    ExternalFormModule,
    SiemensFormsModule,
    BoothTree,
} from '../components';
import { AddBoothDrawer, BoothMenu } from '../components/Booth';
import DownloadBasket from '../components/DownloadBasket';
import EmailTemplates from '../components/EmailTemplates';
import { AddEventModuleDrawer } from '../components/Event';
import { SidebarLayout } from '../components/Layouts';
import LiveStreamRequests from '../components/LiveStreamRequests';
import Sidebar from '../components/Sidebar';
import UserList from '../components/UserList';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title, Paragraph } = Typography;
const CHAT_SUB_KEY = process.env.GATSBY_CHAT_SUB_KEY!;
const CHAT_PUB_KEY = process.env.GATSBY_CHAT_PUB_KEY!;
const loggedInUser = store.get('user') as Users.User;
const strings = new LocalizedStrings({
    en: {
        invalidEvent: 'Invalid event ID',
        ongoing: 'ONGOING',
        ended: 'ENDED',
        yetToStart: 'YET TO START',
        moduleDeleted: 'Module has been deleted',
        selectMenuItem: 'Please select a menu item',
        deleteModule: 'Delete module',
        authModule: 'Authentication Module',
        formsModule: 'Form Module',
        visitorList: 'Visitor List',
        boothCats: 'Booth Categories',
        profFields: 'Profile Fields',
        downloadBasket: 'Download Basket',
        eventAnalytics: 'Event Analytics',
        boothAnalytics: 'Booth Analytics',
        externalFormsModule: 'External Form Module',
        siemensFormsModule: 'Siemens Form Module',
        emailTemplates: 'Email Templates',
        boothTree: 'Booth Tree',
        liveStreams: 'Live Streams',
        selectBooth: 'Please select a booth from the left sidebar.',
        yes: 'Yes',
        no: 'No',
        sure: 'Are you sure you want to delete?',
    },
    de: {
        invalidEvent: 'Ungültige Event-ID',
        ongoing: 'Laufend',
        ended: 'Beendet',
        yetToStart: 'Startet bald',
        moduleDeleted: 'Modul wurde gelöscht',
        selectMenuItem: 'Bitte wählen Sie einen Menüpunkt aus',
        deleteModule: 'Modul löschen',
        authModule: 'Authentifizierungsmodul',
        formsModule: 'Formularmodul',
        visitorList: 'Besucherliste',
        boothCats: 'Standkategorien',
        profFields: 'Profilfelder',
        downloadBasket: 'Warenkorb',
        eventAnalytics: 'Eventanaylsen',
        boothAnalytics: 'Standanalysen',
        externalFormsModule: 'Externes Formularmodul',
        emailTemplates: 'E-Mail-Vorlagen',
        boothTree: 'Standbaum',
        liveStreams: 'Live-Streams',
        selectBooth: 'Bitte wählen Sie ein Stand aus dem linken Menü aus.',
        yes: 'Ja',
        no: 'Nein',
        sure: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
});

const MainContent = styled.div`
    width: 80vw;
    margin-left: 20vw;
    margin-top: 4rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`;

const EventPage = () => {
    const context = useContext(GlobalContext);
    const [clientId, setClientId] = useQueryParam('clientId', StringParam);
    const [eventId, setEventId] = useQueryParam('eventId', StringParam);
    const [boothId, setBoothId] = useQueryParam('boothId', StringParam);
    const [event, setEvent] = useState<Events.Event>();
    const [activeMenuItem, setActiveMenuItem] = useState<{
        type?: 'booth' | 'eventModule';
        booth?: Booths.Booth;
        module?: Modules.Module;
        custom?:
            | 'visitorList'
            | 'boothCategories'
            | 'customUserProfileFields'
            | 'downloadBasketConfig'
            | 'boothAnalytics'
            | 'eventAnalytics'
            | 'emailTemplates'
            | 'boothTree'
            | 'liveStreams';
    }>({});
    const [visibleAddBoothDrawer, setVisibleAddBoothDrawer] = useState(false);
    const [visibleAddEventModuleDrawer, setVisibleAddEventModuleDrawer] = useState(false);
    const [loading, setLoading] = useState(false);

    const pubnubClient = new Pubnub({
        publishKey: CHAT_PUB_KEY,
        subscribeKey: CHAT_SUB_KEY,
        uuid: loggedInUser?._id,
        authKey: loggedInUser?.chatAuthToken,
        restore: true,
        autoNetworkDetection: true,
        presenceTimeout: 60000,
        heartbeatInterval: 10000,
        listenToBrowserNetworkEvents: true,
        keepAliveSettings: {
            timeout: 10000,
        },
    });

    const getEvent = async () => {
        try {
            if (!clientId || !eventId) return navigate('/');
            const event = await apiRequester.getEvent(clientId, eventId);
            context.setActiveEvent({ _id: event._id!, ...event });
            if (event) setEvent(event);
            else throw new Error(strings.invalidEvent);
            return event;
        } catch (err) {
            handleError(err);
        }
    };

    const getEventModules = async () => {
        try {
            if (!clientId || !eventId) return navigate('/');
            const eventModules = await apiRequester.getEventModules(clientId, eventId);
            context.setEventModules(eventModules);
            return eventModules;
        } catch (err) {
            handleError(err);
        }
    };

    const getBooths = async () => {
        try {
            if (!clientId || !eventId) return navigate('/');
            const booths = await apiRequester.getBooths(clientId, eventId);
            context.setBooths(booths);
            return booths;
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        const loggedInUser = store.get('user') as Users.User;
        context.changeUser(loggedInUser);
        if (!clientId || !eventId || !loggedInUser) {
            navigate('/');
            return;
        } else {
            pubnubClient.setUUID(loggedInUser._id!);
            pubnubClient.setAuthKey(loggedInUser.chatAuthToken!);
            context.setPubnub(pubnubClient);
            Promise.all([getEvent(), getEventModules(), getBooths()])
                .then(data => {
                    if (data[2] && data[2].length && !boothId) {
                        // navigate(`/event?clientId=${clientId}&eventId=${eventId}&boothId=${data[2][0]._id}`);
                        // setActiveMenuItem({ type: 'booth', booth: data[2][0] });
                    } else if (data[2] && data[2].length && boothId)
                        setActiveMenuItem({ type: 'booth', booth: data[2].find(booth => booth._id === boothId) });
                    else if (data[1] && data[1].length) setActiveMenuItem({ type: 'eventModule', module: data[1][0] });
                })
                .catch(handleError);
        }
    }, []);

    const showBoothAddDrawer = () => {
        setVisibleAddBoothDrawer(true);
    };

    const showEventModuleAddDrawer = () => {
        setVisibleAddEventModuleDrawer(true);
    };

    const getStatusTag = (event?: Events.Event) => {
        const startTime = new Date(event?.startTime || '');
        const endTime = new Date(event?.endTime || '');
        const currentTime = new Date();
        if (currentTime < startTime) return <Tag>{strings.yetToStart}</Tag>;
        else if (currentTime >= startTime && currentTime <= endTime)
            return <Tag color="#87d068">{strings.ongoing}</Tag>;
        else return <Tag color="#f50">{strings.ended}</Tag>;
    };

    const deleteEventModule = async (boothId: string) => {
        try {
            setLoading(true);
            await apiRequester.removeEventModule(clientId!, eventId!, boothId);
            await context.refreshBooths(clientId!, eventId!);
            handleSuccess(strings.moduleDeleted!);
            navigate('/event-list?clientId=' + clientId);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    return (
        <PubNubProvider client={pubnubClient}>
            <SidebarLayout>
                <Sidebar>
                    <div
                        style={{
                            backgroundColor: '#123da1',
                            padding: '1.5rem',
                            borderRadius: '6px',
                            marginBottom: '1.5rem',
                        }}
                    >
                        <Title level={2} style={{ marginBottom: 0, color: 'white' }}>
                            {context.activeEvent?.name}
                        </Title>
                        <Paragraph style={{ color: '#8cabf2' }}>{event?.description}</Paragraph>
                        {getStatusTag(event)}
                    </div>
                    <BoothMenu
                        showAddBoothDrawer={showBoothAddDrawer}
                        setActiveMenuItem={setActiveMenuItem}
                        showAddEventModuleDrawer={showEventModuleAddDrawer}
                    />
                </Sidebar>

                <MainContent>
                    {!boothId && !activeMenuItem?.custom && !activeMenuItem?.module?.type && (
                        <Layout.Content
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Typography.Text>{strings.selectBooth}</Typography.Text>
                        </Layout.Content>
                    )}

                    {!activeMenuItem && <>{strings.selectMenuItem}</>}

                    {activeMenuItem &&
                        activeMenuItem.type === 'eventModule' &&
                        activeMenuItem.module?.type === 'authentication' && (
                            <>
                                <PageHeader
                                    title={strings.authModule}
                                    onBack={() => window.history.back()}
                                    extra={[
                                        loggedInUser?.roles?.includes('super-admin') && (
                                            <Popconfirm
                                                placement="topLeft"
                                                title={strings.sure}
                                                onConfirm={() => deleteEventModule(activeMenuItem.module?._id!)}
                                                okText={strings.yes}
                                                cancelText={strings.no}
                                            >
                                                <Button key="delete-module" danger>
                                                    {strings.deleteModule}
                                                </Button>
                                            </Popconfirm>
                                        ),
                                    ]}
                                    style={{ backgroundColor: 'white' }}
                                />
                                <div style={{ padding: '1.5rem' }}>
                                    <AuthModule
                                        clientId={clientId!}
                                        eventId={eventId!}
                                        module={
                                            activeMenuItem.module as Required<Modules.Module<Modules.AuthModuleData>>
                                        }
                                    />
                                </div>
                            </>
                        )}

                    {activeMenuItem &&
                        activeMenuItem.type === 'eventModule' &&
                        activeMenuItem.module?.type === 'forms' && (
                            <>
                                <PageHeader
                                    title={strings.formsModule}
                                    onBack={() => window.history.back()}
                                    extra={
                                        <Popconfirm
                                            placement="topLeft"
                                            title={strings.sure}
                                            onConfirm={() => deleteEventModule(activeMenuItem.module?._id!)}
                                            okText={strings.yes}
                                            cancelText={strings.no}
                                        >
                                            <Button key="delete-module" danger>
                                                {strings.deleteModule}
                                            </Button>
                                        </Popconfirm>
                                    }
                                    style={{ backgroundColor: 'white' }}
                                />
                                <div style={{ padding: '1.5rem' }}>
                                    <FormModule
                                        clientId={clientId!}
                                        eventId={eventId!}
                                        moduleId={activeMenuItem.module?._id}
                                        target="event"
                                    />
                                </div>
                            </>
                        )}

                    {activeMenuItem &&
                        activeMenuItem.type === 'eventModule' &&
                        activeMenuItem.module?.type === 'forms-ext' && (
                            <>
                                <PageHeader
                                    title={strings.externalFormsModule}
                                    subTitle={`ID: ${activeMenuItem.module?._id!}`}
                                    onBack={() => window.history.back()}
                                    extra={
                                        <Popconfirm
                                            placement="topLeft"
                                            title={strings.sure}
                                            onConfirm={() => deleteEventModule(activeMenuItem.module?._id!)}
                                            okText={strings.yes}
                                            cancelText={strings.no}
                                        >
                                            <Button key="delete-module" danger>
                                                {strings.deleteModule}
                                            </Button>
                                        </Popconfirm>
                                    }
                                    style={{ backgroundColor: 'white' }}
                                />
                                <div style={{ padding: '1.5rem' }}>
                                    <ExternalFormModule
                                        clientId={clientId!}
                                        eventId={eventId!}
                                        moduleId={activeMenuItem.module?._id!}
                                    />
                                </div>
                            </>
                        )}

                    {activeMenuItem &&
                        activeMenuItem.type === 'eventModule' &&
                        activeMenuItem.module?.type === 'forms-siemens' && (
                            <>
                                <PageHeader
                                    title={strings.siemensFormsModule}
                                    subTitle={`ID: ${activeMenuItem.module?._id!}`}
                                    onBack={() => window.history.back()}
                                    extra={
                                        <Popconfirm
                                            placement="topLeft"
                                            title={strings.sure}
                                            onConfirm={() => deleteEventModule(activeMenuItem.module?._id!)}
                                            okText={strings.yes}
                                            cancelText={strings.no}
                                        >
                                            <Button key="delete-module" danger>
                                                {strings.deleteModule}
                                            </Button>
                                        </Popconfirm>
                                    }
                                    style={{ backgroundColor: 'white' }}
                                />
                                <div style={{ padding: '1.5rem' }}>
                                    <SiemensFormsModule
                                        clientId={clientId!}
                                        eventId={eventId!}
                                        moduleId={activeMenuItem.module?._id!}
                                    />
                                </div>
                            </>
                        )}

                    {activeMenuItem?.custom === 'visitorList' && (
                        <>
                            <PageHeader
                                title={strings.visitorList}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <UserList
                                    clientId={clientId!}
                                    eventId={eventId!}
                                    filterRoles={['visitor']}
                                    showStats={false}
                                />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'boothCategories' && (
                        <>
                            <PageHeader
                                title={strings.boothCats}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <BoothCategories />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'customUserProfileFields' && (
                        <>
                            <PageHeader
                                title={strings.profFields}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <ExtraUserProfileFields />
                            </div>
                        </>
                    )}

                    {activeMenuItem && activeMenuItem.type === 'booth' && activeMenuItem.booth && (
                        <BoothModules clientId={clientId!} eventId={eventId!} boothId={activeMenuItem.booth._id!} />
                    )}

                    {activeMenuItem?.custom === 'downloadBasketConfig' && (
                        <>
                            <PageHeader
                                title={strings.downloadBasket}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <DownloadBasket clientId={clientId!} eventId={eventId!} />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'eventAnalytics' && (
                        <>
                            <PageHeader
                                title={strings.eventAnalytics}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <EventAnalytics clientId={clientId!} eventId={eventId!} />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'boothAnalytics' && (
                        <>
                            <PageHeader
                                title={strings.boothAnalytics}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <BoothAnalytics clientId={clientId!} eventId={eventId!} boothId={boothId!} />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'emailTemplates' && (
                        <>
                            <PageHeader
                                title={strings.emailTemplates}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <EmailTemplates clientId={clientId!} eventId={eventId!} />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'boothTree' && (
                        <>
                            <PageHeader
                                title={strings.boothTree}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <BoothTree clientId={clientId!} eventId={eventId!} />
                            </div>
                        </>
                    )}

                    {activeMenuItem?.custom === 'liveStreams' && (
                        <>
                            <PageHeader
                                title={strings.liveStreams}
                                onBack={() => window.history.back()}
                                style={{ backgroundColor: 'white' }}
                            />
                            <div style={{ padding: '1.5rem' }}>
                                <LiveStreamRequests clientId={clientId!} eventId={eventId!} />
                            </div>
                        </>
                    )}
                </MainContent>

                {!loggedInUser?.roles?.includes('sponsor') && (
                    <>
                        <AddBoothDrawer
                            clientId={clientId!}
                            eventId={eventId!}
                            visible={visibleAddBoothDrawer}
                            setVisible={setVisibleAddBoothDrawer}
                        />

                        <AddEventModuleDrawer
                            clientId={clientId!}
                            eventId={eventId!}
                            visible={visibleAddEventModuleDrawer}
                            setVisible={setVisibleAddEventModuleDrawer}
                        />
                    </>
                )}
            </SidebarLayout>
            <VideoCallModal />
        </PubNubProvider>
    );
};

export default EventPage;
