import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { CloseOutlined, FormOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, Space, Typography } from 'antd';
import LocalizedStrings from 'react-localization';

import { GlobalContext } from '../../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../../utility';

const { Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        moduleActivated: 'module has been activated',
        addModule: 'Add Event Module',
        authModule: 'Authentication Module',
        add: 'Add',
        authDescription1:
            'This module allows you to require a sign up and sign in before entering the event. By default the event will be open to all visitors and they will be able to attend it without going thorough an authentication process. Without authentication, user&apos;s will still have to fill up a small registration form with their first and last name, email and company name to help with user identification but won&apos;t be asked to login to attend the event.',
        authDescription2: 'When this module is activated, visitors will be asked to verify their email before sign in.',
        externalFormsModule: 'External Form Module',
        siemensFormsModule: 'Siemens Form Module',
    },
    de: {
        moduleActivated: 'Modul wurde aktiviert',
        addModule: 'Eventmodul hinzufügen',
        authModule: 'Authentifizierungsmodul',
        add: 'Hinzufügen',
        authDescription1:
            'Dieses Modul macht eine Registrierung vor der Teilnahme an der Veranstaltung für die Besucher verpflichtend. Standardmäßig steht die Veranstaltung allen Besuchern offen und sie können daran teilnehmen, ohne einen Authentifizierungsprozess durchlaufen zu müssen. Ohne Authentifizierung müssen die Benutzer weiterhin ein Registrierungsformular mit ihrem Vor- und Nachnamen, E-Mail und Firmennamen ausfüllen, um die Benutzeridentifikation zu erleichtern, werden jedoch nicht aufgefordert, sich anzumelden, um an der Veranstaltung teilzunehmen.',
        authDescription2:
            'Wenn dieses Modul aktiviert wird, müssen Besucher ihre E-Mail-Adresse bestätigen, bevor sie sich registrieren können.',
    },
});

export const AddEventModuleDrawer = ({
    clientId,
    eventId,
    visible,
    setVisible,
}: {
    clientId: string;
    eventId: string;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    const closeDrawer = () => setVisible(false);

    const addModule = async (moduleName?: Modules.MODULE_IDS) => {
        try {
            setLoading(true);
            await apiRequester.addEventModule(clientId!, eventId!, { type: moduleName! });
            const eventModules = await apiRequester.getEventModules(clientId!, eventId!);
            context.setEventModules(eventModules);
            closeDrawer();
            handleSuccess(`${moduleName?.charAt(0).toUpperCase()}${moduleName?.slice(1)} ${strings.moduleActivated}`);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleError(err);
        }
    };

    return (
        <Drawer
            title={strings.addModule}
            placement="right"
            closable={false}
            onClose={closeDrawer}
            visible={visible}
            width="620px"
            footer={
                <Space style={{ marginRight: '8px' }}>
                    <Button loading={loading} onClick={closeDrawer}>
                        Cancel
                    </Button>
                </Space>
            }
            footerStyle={{ textAlign: 'right', marginRight: '8px' }}
            closeIcon={<CloseOutlined />}
        >
            <Space direction="vertical">
                <Card
                    title={strings.authModule}
                    extra={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => addModule('authentication')}
                            key="add-authentication-module-btn"
                        >
                            {strings.add}
                        </Button>,
                    ]}
                >
                    <Paragraph>
                        <LockOutlined style={{ fontSize: '5rem' }} />
                    </Paragraph>
                    <Paragraph>{strings.authDescription1}</Paragraph>
                    <Paragraph>{strings.authDescription2}</Paragraph>
                </Card>

                {/* <Card
                    title="Forms Module"
                    extra={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => addModule('forms')}
                            key="add-forms-module-btn"
                        >
                            Add
                        </Button>,
                    ]}
                >
                    <Paragraph>
                        <FormOutlined style={{ fontSize: '5rem' }} />
                    </Paragraph>
                    <Paragraph>
                        This module allows you to ask the visitor to fill up a small form that can have open ended or
                        multiple choice questions. This form is sent to the user after the initial registration process.
                    </Paragraph>
                    <Paragraph>All responses are recorded and can be viewed or exported in CSV format.</Paragraph>
                </Card> */}

                <Card
                    title={strings.externalFormsModule}
                    extra={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => addModule('forms-ext')}
                            key="add-forms-module-btn"
                        >
                            Add
                        </Button>,
                    ]}
                >
                    <Paragraph>
                        <FormOutlined style={{ fontSize: '5rem' }} />
                    </Paragraph>
                    <Paragraph>
                        This module allows you to record responses of forms that can be designed in the frontend.
                    </Paragraph>
                    <Paragraph>All responses are recorded and can be viewed or exported in CSV format.</Paragraph>
                </Card>

                <Card
                    title={strings.siemensFormsModule}
                    extra={[
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={() => addModule('forms-siemens')}
                            key="add-forms-module-btn"
                        >
                            Add
                        </Button>,
                    ]}
                >
                    <Paragraph>
                        <FormOutlined style={{ fontSize: '5rem' }} />
                    </Paragraph>
                    <Paragraph>
                        This is a Siemens specific modules. This module allows you to record responses of forms that can
                        be designed in the frontend.
                    </Paragraph>
                    <Paragraph>All responses are recorded and can be viewed or exported in CSV format.</Paragraph>
                </Card>
            </Space>
        </Drawer>
    );
};

export default AddEventModuleDrawer;
